import React, { useState, useEffect } from 'react';
import { Paper, Typography, Chip, Box } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { isEmpty } from 'ramda';

function TrackingWidget(props) {
  const { widget } = props;

  // Estado para manejar la renderización de datos
  const [awaitRender, setAwaitRender] = useState(true);
  const [dataWidget, setDataWidget] = useState({});

  // Usamos useEffect para cargar los datos
  useEffect(() => {
    const fetch = async () => {
      if (widget && !isEmpty(widget)) {
        setDataWidget(widget);
      }
    };
    fetch();
  }, [widget]);

  // Opciones del gráfico
  const chartOptions = {
    chart: {
      animations: {
        speed: 400,
        animateGradually: {
          enabled: false
        }
      },
      fontFamily: 'inherit',
      foreColor: 'inherit',
      height: '90%',
      type: 'donut',
      sparkline: {
        enabled: true
      }
    },
    colors: ['#E53E3E', '#3182CE', '#38A169', '#718096', '#805AD5'],
    labels: dataWidget.labels || [],
    plotOptions: {
      pie: {
        customScale: 0.9,
        expandOnClick: false,
        donut: {
          size: '70%'
        }
      }
    },
    stroke: {
      colors: ['transparent']
    },
    series: dataWidget.series || [],
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      },
      active: {
        filter: {
          type: 'none'
        }
      }
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: 'dark',
      custom: ({ seriesIndex, w }) => `
        <div class="flex items-center h-10 min-h-10 max-h-23 px-12">
          <div class="w-6 h-6 rounded-full" style="background-color: ${w.config.colors[seriesIndex]};"></div>
          <div class="ml-8 text-md leading-none">${w.config.labels[seriesIndex]}:</div>
          <div class="ml-8 text-md font-bold leading-none">${w.config.series[seriesIndex]}</div>
        </div>`
    }
  };

  // Actualizamos el estado de renderización una vez los datos estén disponibles
  useEffect(() => {
    setAwaitRender(false);
  }, []);

  // Si los datos aún no se han cargado, no renderizamos nada
  if (awaitRender || !dataWidget || !dataWidget.labels || !dataWidget.series) {
    return null;
  }

  return (
    <Paper className="flex flex-col sm:flex-row shadow rounded-2xl overflow-hidden" style={{ maxWidth: '500px', padding: 20 }}>
      {/* Columna izquierda (Gráfico) */}
      <div className="flex flex-col flex-auto h-192" style={{ width: '50%' }}>
        <Typography className="text-lg font-medium tracking-tight leading-6 truncate mb-4">
          Seguimientos
        </Typography>
        <ReactApexChart
          className="flex flex-auto items-center justify-center w-full h-full"
          options={chartOptions}
          series={dataWidget.series}
          type={chartOptions?.chart?.type}
          height={chartOptions?.chart?.height}
        />
      </div>

      {/* Columna derecha (Tabla con los datos) */}
      <div className="flex flex-col justify-start items-start ml-8" style={{ width: '50%' }}>
        <Chip
          size="small"
          className="font-medium text-sm mb-4"
          label={`Total ${dataWidget.uniqueVisitors}`}
        />
        {dataWidget.labels.map((label, index) => (
          <div key={index} className="flex items-center mb-2 w-full">
            {/* Columna 1: Estado */}
            <div className="flex items-center w-60">
              <Box
                className="w-4 h-4 rounded-full mr-4"
                sx={{ backgroundColor: chartOptions?.colors[index] }}
              />
              <Typography className="font-medium">{label}</Typography>
            </div>

            {/* Columna 2: Cantidad */}
            <div className="flex justify-start w-20">
              <Typography className="font-medium text-right">
                {dataWidget.series[index]}
              </Typography>
            </div>

            {/* Columna 3: Porcentaje */}
            <div className="flex justify-start w-20">
              <Typography color="textSecondary">
                {((dataWidget.series[index] / dataWidget.uniqueVisitors) * 100).toFixed(2)}%
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </Paper>
  );
}

export default TrackingWidget;
