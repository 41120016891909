import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';
import clsx from "clsx";
import { isEmpty } from 'ramda';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  InputAdornment,

  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControl,
  FormHelperText,
  Link,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import { format, parseISO, parse, isValid } from 'date-fns';

import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';
import CustomUploadFile from '@components/CustomUploadFile';
import CustomTableHead from '@components/CustomTableHead';
import CustomClickAwayListener from '@components/CustomClickAwayListener';
import UploadFileDialog from './ViewFileDialog';

import API from '../../../configs/urlsConfig';
import { showMessage } from '../../../store/messageSlice';
import { createActivityFile, saveReceptionInfo, deleteActivityFile, getActivityFile } from '../../../store/supplierTrackingSlice';

const useStyles = makeStyles({
  dialog: {
    // width: "100%",
    // backgroundColor: 'rgba(80, 80, 80, .5)',
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 600,
      width: '100%',
    },
    // "& .MuiDialog-paperWidthMd": {
    //   maxWidth: 600,
    //   width: '100%',
    //   // backgroundColor: 'white',
    // },
  },
});

const headCells = [
  {
    id: 'acfi_name',
    order: false,
    label: 'Nombre'
  },
  {
    id: 'acfi_file',
    order: false,
    label: 'Archivo',
  },
  {
    id: 'action',
    order: false,
    label: 'Action',
  },
];

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultValues = {
  acfi_file: null,
  acfi_name: '',
};

const schema = yup.object().shape({
  acfi_name: yup.string().required('Nombre es requerido'),
  acfi_file: yup.mixed().required('Archivo es requerido'),
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function ReportDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const activityFiles = useSelector(({ supplierTrack }) => supplierTrack.activityFiles);

  const [items, setItems] = useState([]);
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'acfi_id',
  });
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingImages, setLoadingImages] = useState([]);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { reset, control, formState, handleSubmit, setValue } = methods;
  const { errors } = formState;


  useEffect(() => {
    const fetch = async () => {
      if (activityFiles) {
        setItems(activityFiles);
      }
    };
    fetch();
  }, [activityFiles]);

  useEffect(() => {
    async function init() {
      await dispatch(getActivityFile(item?.ass_id, item?.ass_step));
    }
    init();

    return async () => {

    }
  }, [open]);

  /**
  * Handles the table sort request.
  * @param {string} property - Property to sort the table by.
  */
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };

  /**
* Function to clean the information
*/
  const cleanData = () => {
    reset(defaultValues);
  };

  // --------------------------------------------
  const handleUploadChange = async (eventFile) => {
    const file = eventFile[0];
    if (!file) {
      return;
    }
    const formatType =
      file.type === 'application/pdf'
        ? 'pdf'
        : file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          ? 'docx'
          : file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ? 'xlsx'
            : file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
              ? 'xlspptxx'
              : file.type === 'image/png'
                ? 'png'
                : 'jpeg';
    const data = {
      file,
      hash_archivo: `${md5(Date.now())}.${formatType}`,
    };
    setValue('acfi_file', data);
    errors.acfi_file = false;
  };

  const handleRemoveFile = () => {
    setValue('acfi_file', null);
  };

  const handleUploadFile = async (data) => {
    setLoadingFile(true);
    const formData = new FormData();
    formData.append('file', data.acfi_file.file);
    formData.append('hash_archivo', data.acfi_file.hash_archivo);
    formData.append('acfi_name', data.acfi_name);
    formData.append('user_id', item?.user_id);
    formData.append('acfi_activities_service_seller_id', item?.ass_id);

    const response = await dispatch(createActivityFile(formData));
    if (response) {
      await dispatch(showMessage({ message: 'Archivo cargardo con exito', variant: 'success' }));
      await dispatch(getActivityFile(item?.ass_id, item?.ass_step));
    }
    cleanData();
    setLoadingFile(false);
  }

  const handleAction = async (data) => {
    setLoading(true);
    const response = await dispatch(saveReceptionInfo({
      ass_id: item?.ass_id,
      service_id: item?.fk_seller_service?.sese_service_id, // Randall medina
      user_id: item.user_id,
    }));
    if (response) {
      await dispatch(showMessage({ message: 'Archivo cargardo con exito', variant: 'success' }));
    }
    setLoading(false);
    handleClose()
    handleRefresh()
  }

  const handleDeleteFile = async (row) => {
    setLoadingImages((prevLoading) => [...prevLoading, row.acfi_id]);

    const response = await dispatch(deleteActivityFile({ user_id: item?.user_id, acfi_id: row.acfi_id }))
    if (response) {
      await dispatch(showMessage({ message: 'Archivo eliminado con exito', variant: 'success' }));
      await dispatch(getActivityFile(item?.ass_id, item?.ass_step));
    }
    cleanData();
  }

  return (
    <Dialog open={open} TransitionComponent={Transition} className={classes.dialog}>
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          Informe final
        </p>
      </DialogTitle>
      <DialogContent /*sx={{ width: '800px' }}*/ >
        <FormProvider {...methods}>
          <div className=''>
            <p className="text-16 font-bold my-2 flex">
              <DescriptionRoundedIcon style={{ color: '#213462', marginRight: 3 }} /> {' '}
              Archivos</p>
            <div className='xl:col-span-1 col-span-2'>
              <div className='mb-2'>
                <Typography color="#262940" className="text-16">
                  Adjuntar documento de soporte{' '}
                  <span style={{ color: '#B7BECD' }}>(jpg, jpeg, png, docx, xlsx, pptx, pdf):</span>
                </Typography>
              </div>
              <Controller
                name="acfi_file"
                control={control}
                render={({ field }) => (
                  <CustomUploadFile
                    {...field}
                    id="acfi_file"
                    handleUploadChange={(e) => handleUploadChange(e)}
                    handleRemoveFile={(e) => handleRemoveFile(e)}
                    accept={{
                      'application/pdf': ['.pdf'],
                      'image/*': ['.jpg', '.jpeg', '.png'],
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                        '.doc',
                        '.docx',
                      ],
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
                    }}
                    required={!!errors.acfi_file}
                  />
                )}
              />
              <div className=''>
                <FormControl error={!!errors.acfi_file} className="w-full">
                  <FormHelperText style={{ marginLeft: 0 }}>{errors?.acfi_file?.message}</FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className='xl:col-span-1 col-span-2'>
                <Controller
                  name="acfi_name"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      styles={{ mt: 1, mb: 2, width: '100%' }}
                      id="acfi_name"
                      label="Nombre archivo"
                      // value={form.cli_documento}
                      error={!!errors.acfi_name}
                      helperText={errors?.acfi_name?.message}
                      required
                    />
                  )}
                />
              </div>
              <div className='xl:col-span-1 col-span-2 mt-4'>
                {loadingFile ? (
                  <CircularProgress
                    style={{ color: '#4575D5', marginRight: 4 }}
                    size={24}
                  />
                ) : (
                  <CustomButton
                    label="Subir archivo"
                    typebutton={2}
                    onClick={handleSubmit(handleUploadFile)}
                  />
                )}
              </div>
            </div>
          </div>
        </FormProvider>
        {/* ---------------------- */}
        <TableContainer>
          <Table size="small" className="simple mt-4">
            <CustomTableHead
              order={order.direction}
              orderBy={order.id}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {items.length > 0 ? items.map((row) => (
                <TableRow
                  key={row.acfi_id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.acfi_name}</TableCell>
                  <TableCell
                    align="left"
                    sx={{ p: '14px 3px' }}
                  >
                    {row.acfi_file && (
                      <Link
                        target="_blank"
                        href={`${API.url_ftp}/${item?.user_id}/seguimientos/${row.acfi_file}`}
                        color="#213462"
                        className="text-12 font-bold cursor-pointer"
                      >
                        {/* row.acfi_file */}
                        Ver
                      </Link>
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ p: '14px 3px' }}
                  >
                    {loadingImages.includes(row.acfi_id) ? (
                      <CircularProgress
                        style={{ color: '#4575D5', marginRight: 4 }}
                        size={24}
                      />
                    ) :
                      <IconButton
                        onClick={async () => {
                          handleDeleteFile(row)
                        }}
                      >
                        <DeleteSweepRoundedIcon
                          style={{ color: '#213462' }}
                        />
                      </IconButton>
                    }
                  </TableCell>
                </TableRow>
              )) :
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ padding: 16, fontSize: 12 }}
                    colSpan={5}
                  >
                    No se han encontrado archivos
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        {/* ---------------------- */}
        <div className="flex justify-end my-4 items-center">
          <p
            className="mx-4 underline text-base text-gray-600 cursor-pointer"
            onClick={() => {
              handleClose();
              setLoading(false);
            }}
          >
            Cerrar
          </p>
          {loading ? (
            <CircularProgress
              style={{ color: '#4575D5', marginRight: 4 }}
              size={24}
            />
          ) : (
            <CustomButton
              label="Enviar información"
              typebutton={2}
              onClick={handleAction}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ReportDialog;
