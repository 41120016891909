import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';
import { errorResponse } from '../utils/httpResponse'

// *** ROL SELLER ***
export const getActivitiesById = (userId, group, page, per_page, orderBy, order, params) => async (dispatch) => {
    try {
        const queryString = `?group=${group}&page=${page}&rows=${per_page}&order_field=${orderBy}&order_type=${order}&filters=${JSON.stringify(params)}`;
        const URL = `${API.baseUrl}/${API.endpoints.seller_activities}/user/${userId}`;
        const result = await axios.get(URL + queryString);
        await dispatch(setDataById(result.data));
        return result;
    } catch (error) {
        return await errorResponse(dispatch, error, 'getActivitiesById')
    }
};

// *** ROL BOSANET ***
export const getActivityType = () => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_activities}/type`;
        const result = await axios.get(URL);
        await dispatch(setActivityTypeList(result.data));
        return true;
    } catch (error) {
        return await errorResponse(dispatch, error, 'getBuyers')
    }
};

// *** ROL BOSANET ***
export const getAll = (group, page, per_page, orderBy, order, params) => async (dispatch) => {
    try {
        const queryString = `?group=${group}&page=${page}&rows=${per_page}&order_field=${orderBy}&order_type=${order}&filters=${JSON.stringify(params)}`;
        const URL = `${API.baseUrl}/${API.endpoints.seller_activities}`;
        const result = await axios.get(URL + queryString);
        await dispatch(setDataList(result.data));
        return result;
    } catch (error) {
        return await errorResponse(dispatch, error, 'getAll')
    }
};

// *** ROL BOSANET ***
export const create = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_activities}`;
        const result = await axios.post(URL, data);
        return result.data;
    } catch (error) {
        return await errorResponse(dispatch, error, 'create')
    }
};

// *** ROL BOSANET ***
export const update = (data, id) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_activities}/${id}`;
        await axios.put(URL, data);
        return true;
    } catch (error) {
        return await errorResponse(dispatch, error, 'update')
    }
};

// *** ROL BOSANET ***
export const getSellerActivityFile = (id) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_activities}/file/${id}`;
        const result = await axios.get(URL);
        await dispatch(setFiles(result.data));
        return true;
    } catch (error) {
        return await errorResponse(dispatch, error, 'getSellerActivityFile')
    }
};

// *** ROL BOSANET ***
export const createSellerActivityFile = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_activities}/file`;
        await axios.post(URL, data, { headers: { 'Content-Type': 'multipart/form-data' } });
        return true;
    } catch (error) {
        return await errorResponse(dispatch, error, 'createSellerActivityFile')
    }
};

// *** ROL BOSANET ***
export const deleteSellerActivityFile = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_activities}/file_delete`;
        await axios.post(URL, data);
        return true;
    } catch (error) {
        return await errorResponse(dispatch, error, 'deleteSellerActivityFile')
    }
};

const sellerActivitiesSlice = createSlice({
    name: 'sellerActi',
    initialState: {
        enabledIntegration: false,
        dataList: [],
        page: 0,
        rowsPerPage: 10,
        total: 0,

        dataById: [],
        pageTwo: 0,
        rowsPerPageTwo: 10,
        totalTwo: 0,

        activityTypeList: [],
        files: [],
        sellersList: [],
    },
    reducers: {
        setCleanSellerActi: (state) => {
            state.dataById = {};
        },
        setCleanFile: (state) => {
            state.files = [];
        },
        setDataList: (state, action) => {
            state.dataList = action.payload.rows;
            state.total = action.payload.count;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },

        setDataById: (state, action) => {
            state.dataById = action.payload.rows;
            state.totalTwo = action.payload.count;
        },
        setRowsPerPageTwo: (state, action) => {
            state.rowsPerPageTwo = action.payload;
        },
        setPageTwo: (state, action) => {
            state.pageTwo = action.payload;
        },
        setActivityTypeList: (state, action) => {
            state.activityTypeList = action.payload;
        },
        setFiles: (state, action) => {
            state.files = action.payload;
        },
        setSellersList: (state, action) => {
            state.sellersList = action.payload;
        },
    }
});

export const { setCleanSellerActi, setDataList, setRowsPerPage, setPage, setTotalRows,
    setDataById, setRowsPerPageTwo, setPageTwo, setActivityTypeList, setFiles, setCleanFile,
    setSellersList
} =
    sellerActivitiesSlice.actions;

export default sellerActivitiesSlice.reducer;
