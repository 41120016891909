import { configureStore } from '@reduxjs/toolkit';

import i18nSlice from './i18nSlice';
import showMessage from './messageSlice';
import userSlice from './userSlice';
import sellerSlice from './sellerSlice';
import documentTypeSlice from './documentTypeSlice';
import citySlice from './citySlice';
import stateSlice from './stateSlice';
import serviceGroupSlice from './serviceGroupSlice';
import roleSlice from './roleSlice';
import serviceSlice from './serviceSlice';
import questionSlice from './questionSlice';
import answerSlice from './answerSlice';
import personSlice from './personSlice';
import jobSlice from './jobSlice';
import diagnosticSlice from './diagnosticSlice';
import sellerServicesSlice from './sellerServicesSlice';
import sellerTrackingSlice from './sellerTrackingSlice';
import sellerActivitySlice from './sellerActivitySlice';
import buyerSlice from './buyerSlice';
import employeeSlice from './employeeSlice';
import supplierSlice from './supplierSlice';
import supplierServicesSlice from './supplierServicesSlice';
import employeeServicesSlice from './employeeServicesSlice';
import employeeTrackingSlice from './employeeTrackingSlice';
import supplierTrackingSlice from './supplierTrackingSlice';
import partnerSlice from './partnerSlice';
import templateSlice from './templateSlice';
import sellerActivitiesGroups from "./sellerActivitiesGroups";
import sellerActivitiesTypes from "./sellerActivitiesTypes";

export default configureStore({
  reducer: {
    i18n: i18nSlice,
    message: showMessage,
    user: userSlice,
    seller: sellerSlice,
    docType: documentTypeSlice,
    city: citySlice,
    state: stateSlice,
    serGroup: serviceGroupSlice,
    role: roleSlice,
    service: serviceSlice,
    question: questionSlice,
    answer: answerSlice,
    person: personSlice,
    job: jobSlice,
    diagnostic: diagnosticSlice,
    sellerServ: sellerServicesSlice,
    sellerTrack: sellerTrackingSlice,
    sellerActi: sellerActivitySlice,
    buyer: buyerSlice,
    employee: employeeSlice,
    supplier: supplierSlice,
    supplierServ: supplierServicesSlice,
    employeeServ: employeeServicesSlice,
    employeeTrack: employeeTrackingSlice,
    supplierTrack: supplierTrackingSlice,
    partner: partnerSlice,
    template: templateSlice,
    slaGroup: sellerActivitiesGroups,
    seacType: sellerActivitiesTypes,
  },
  devTools: true,
});
