import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';
import { errorResponse } from '../utils/httpResponse'

// *** ROL SELLER ***
export const getServicesById = (userId, page, per_page, orderBy, order, params) => async (dispatch) => {
    try {
        const queryString = `?page=${page}&rows=${per_page}&order_field=${orderBy}&order_type=${order}&filters=${JSON.stringify(params)}`;
        const URL = `${API.baseUrl}/${API.endpoints.seller_services}/user/${userId}`;
        const result = await axios.get(URL + queryString);
        await dispatch(setDataById(result.data));
        return result;
    } catch (error) {
        throw new Error(`*** REDUX -> getServicesById *** ${error}`);
    }
};

export const getAll = (page, per_page, orderBy, order, params) => async (dispatch) => {
    try {
        const queryString = `?page=${page}&rows=${per_page}&order_field=${orderBy}&order_type=${order}&filters=${JSON.stringify(params)}`;
        const URL = `${API.baseUrl}/${API.endpoints.seller_services}`;
        const result = await axios.get(URL + queryString);
        await dispatch(setDataList(result.data));
        return result;
    } catch (error) {
        return await errorResponse(dispatch, error, 'getAll')
    }
};

export const create = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_services}`;
        await axios.post(URL, data);
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> create *** ${error}`);
    }
};

export const update = (data, id) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_services}/${id}`;
        await axios.put(URL, data);
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> update *** ${error}`);
    }
};

export const getActivityById = (id) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_services}/tracking?seller_service_id=${id}`;
        const result = await axios.get(URL);
        await dispatch(setTrackById(result.data));
        return result;
    } catch (error) {
        return await errorResponse(dispatch, error, 'errorResponse')
    }
};

export const getServiceDocumentById = (service) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_services}/service_document/${service}`;
        const result = await axios.get(URL);
        await dispatch(setServiceDocuments(result.data));
        return result;
    } catch (error) {
        return await errorResponse(dispatch, error, 'getServiceDocumentById')
    }
};


export const getActivityFile = (activity_id, step) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_services}/activity_file?activity_id=${activity_id}&step=${step}`;
        const result = await axios.get(URL);
        await dispatch(setActivityFiles(result.data));
        return true;
    } catch (error) {
        return await errorResponse(dispatch, error, 'getActivityFile')
    }
};

export const createActivityFile = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_services}/activity_file`;
        await axios.post(URL, data, { headers: { 'Content-Type': 'multipart/form-data' } });
        return true;
    } catch (error) {
        return await errorResponse(dispatch, error, 'createActivityFile')
    }
};

export const deleteActivityFile = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_services}/activity_file_delete`;
        await axios.post(URL, data);
        return true;
    } catch (error) {
        return await errorResponse(dispatch, error, 'deleteActivityFile')
    }
};


const sellerServicesSlice = createSlice({
    name: 'sellerServ',
    initialState: {
        enabledIntegration: false,
        dataList: [],
        dataById: [],
        page: 0,
        rowsPerPage: 10,
        total: 0,
        refreshSellerServ: false,
        
        trackById: [],
        serviceDocuments: [],
        activityFiles: [],

        // *** ROL SELLER ***
        pageTwo: 0,
        rowsPerPageTwo: 10,
        totalTwo: 0,
        totalServices: 0,
        totalSubscription: 0,
        totalPayment: 0,
    },
    reducers: {
        setCleanSellerServ: (state) => {
            state.dataById = {};
        },
        setDataList: (state, action) => {
            state.dataList = action.payload.rows;
            state.total = action.payload.count;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setRefreshSellerServ: (state, action) => {
            state.refreshSellerServ = action.payload;
        },

        setDataById: (state, action) => {
            state.dataById = action.payload.rows;
            state.totalTwo = action.payload.count;
            state.totalService = action.payload.service;
            state.totalSubscription = action.payload.subscription;
            state.totalPayment = action.payload.totalPayment;
        },
        setRowsPerPageTwo: (state, action) => {
            state.rowsPerPageTwo = action.payload;
        },
        setPageTwo: (state, action) => {
            state.pageTwo = action.payload;
        },


        setTrackById: (state, action) => {
            state.trackById = action.payload;
        },
        setCleanTrack: (state) => {
            state.trackById = [];
            state.serviceDocuments = [];
        },
        setCleanTrackUp: (state) => {
            state.activityFiles = [];
        },
        setServiceDocuments: (state, action) => {
            state.serviceDocuments = action.payload;
        },
        setActivityFiles: (state, action) => {
            state.activityFiles = action.payload;
        },
    }
});

export const { setCleanSellerServ, setDataList, setRowsPerPage, setPage, setRefreshSellerServ, setTotalRows, setDataById,
    setRowsPerPageTwo, setPageTwo,
    setTrackById, setServiceDocuments, setCleanTrack, setActivityFiles, setCleanTrackUp
 } =
    sellerServicesSlice.actions;

export default sellerServicesSlice.reducer;
