import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import clsx from "clsx";
import { format, parseISO } from 'date-fns'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Link,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FilterRoundedIcon from '@mui/icons-material/FilterRounded';

import CustomTableHead from '@components/CustomTableHead';

import { showMessage } from '../../../store/messageSlice';
import API from '../../../configs/urlsConfig';
import { getServiceDocumentById } from '../../../store/employeeTrackingSlice';

const useStyles = makeStyles({
  dialog: {
    // width: "100%",
    // backgroundColor: 'rgba(80, 80, 80, .5)',
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 800,
      width: '100%',
    },
    // "& .MuiDialog-paperWidthMd": {
    //   maxWidth: 600,
    //   width: '100%',
    //   // backgroundColor: 'white',
    // },
  },
});

const headCells = [
  {
    id: 'sd_name',
    order: false,
    label: 'Nombre'
  },
  {
    id: 'sd_file',
    order: false,
    label: 'Archivo',
  },
];

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function DownloadFileDialog(props) {
  const { open, handleClose, type, item } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const serviceDocuments = useSelector(({ employeeTrack }) => employeeTrack.serviceDocuments);
  const [items, setItems] = useState([]);
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'sd_id',
  });

  useEffect(() => {
    const fetch = async () => {
      if (serviceDocuments) {
        setItems(serviceDocuments);
      }
    };
    fetch();
  }, [serviceDocuments]);

  useEffect(() => {
    async function init() {
      await dispatch(getServiceDocumentById(item?.fk_seller_service?.sese_service_id));
    }
    init();

    return async () => {

    }
  }, [open]);

  /**
   * Handles the table sort request.
   * @param {string} property - Property to sort the table by.
   */
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
    // style={{
    //   boxShadow: '0px 2px 16px 4px rgba(38, 41, 64, 0.1)',
    //   zIndex: 99,
    // }}
    // className={classes.dialog}
    // minWidth="lg"
    // maxWidth="lg"
    >
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          Descargar
        </p>
        <IconButton key="close" aria-label="Close" onClick={() => handleClose()} style={{ position: 'absolute', right: 16 }}>
          <CloseRoundedIcon style={{ color: '#213462' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: '400px' }} /* sx={{ width: '600px' }} */>
        {/* ------------ */}
        <div className="mt-4">
          <TableContainer>
            <Table size="small" className="simple mt-4">
              <CustomTableHead
                order={order.direction}
                orderBy={order.id}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <TableBody>
                {items.length > 0 ? items.map((row) => (
                  <TableRow
                    key={row.sd_id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.sd_name}</TableCell>
                    <TableCell
                      align="left"
                      sx={{ p: '14px 3px' }}
                    >
                      {row.sd_file && (
                        <Link
                          target="_blank"
                          href={`${API.url_ftp}/servicios/${row.sd_file}`}
                          color="#213462"
                          className="text-12 font-bold cursor-pointer"
                        >
                          {/* row.sd_file */}
                          Ver
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                )) :
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ padding: 16, fontSize: 12 }}
                      colSpan={5}
                    >
                      No se han encontrado datos
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* ------------ */}
        <div className="flex justify-end my-4 items-center">
          <p
            className="mx-4 underline text-base text-gray-600 cursor-pointer"
            onClick={() => {
              handleClose();
            }}
          >
            Cerrar
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DownloadFileDialog;
