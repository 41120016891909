import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar
} from '@mui/material';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

import { showMessage } from '../../../store/messageSlice';
import API from '../../../configs/urlsConfig';

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${height * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function EmailDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  const dispatch = useDispatch();

  return (
    <Dialog open={open} TransitionComponent={Transition} minWidth="md"
      maxWidth="md">
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          Copia de correo enviado
        </p>
        <IconButton key="close" aria-label="Close" onClick={() => handleClose()} style={{ position: 'absolute', right: 16 }}>
          <CloseRoundedIcon style={{ color: '#213462' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent /*sx={{ width: '400px' }}*/>
        <div className="content-preview mt-5" dangerouslySetInnerHTML={{ __html: JSON.parse(item.seac_copy_email) }} />
        {/* ------------ */}
        <div className="flex justify-end my-4 items-center">
          <p
            className="mx-4 underline text-base text-gray-600 cursor-pointer"
            onClick={() => {
              handleClose();
            }}
          >
            Cerrar
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default EmailDialog;
