import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Tabs, Tab, Box
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';
import CustomUploadFile from '@components/CustomUploadFile';
import CustomTableHead from '@components/CustomTableHead';

import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';

import {
  create,
  update,
} from '../../../store/sellerActivitiesTypes';
import {
  getGroupsDropdown
} from '../../../store/sellerActivitiesGroups';
import {
  getTemplateDropdown,
} from '../../../store/templateSlice';
import { showMessage } from '../../../store/messageSlice';

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  popper: {
    '& .MuiPopper-root': { zIndex: 9999 },
  },
  dialog: {
    // width: "100%",
    // backgroundColor: 'rgba(80, 80, 80, .5)',
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 750,
      width: '100%',
    },
    // "& .MuiDialog-paperWidthMd": {
    //   maxWidth: 600,
    //   width: '100%',
    //   // backgroundColor: 'white',
    // },
  },
}));

const defaultValues = {
  sat_name: '',
  sat_notification: null,
  sat_email_templates_id: null,
  sat_group_id: null,
};

const schema = yup.object().shape({
  sat_name: yup.string().required('Nombre es requerido'),
  sat_notification: yup.object().required('Notificación es requerida'),
  sat_email_templates_id: yup.object().required('Plantilla es requerida'),
  sat_group_id: yup.object().required('Grupo es requerido'),
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function CustomDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  //-------------------------------------------
  const groups = useSelector(({ slaGroup }) => slaGroup.groupDropdown);
  const templates = useSelector(({ template }) => template.templateDropdown);
  //-------------------------------------------
  const [loading, setLoading] = useState(false);
  const [mainId, setMainId] = useState(null);
  const [valueTab, setValueTab] = useState(0);
  //-------------------------------------------
  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { reset, control, formState, handleSubmit, setValue } = methods;
  const { errors } = formState;
  //-------------------------------------------
  const initDialog = useCallback(async () => {
    reset({
      ...item,
      sat_email_templates_id: item.sat_email_templates_id === null || item.sat_email_templates_id === '' ? null : {
        label: item.fk_email_templates?.template_name,
        value: item.sat_email_templates_id,
      },
      sat_group_id: item.sat_group_id === null || item.sat_group_id === '' ? null : {
        label: item.fk_groups?.seacg_name,
        value: item.sat_group_id,
      },
      sat_notification: {
        label: item.sat_notification === 0 ? "No" : "Si",
        value: item.sat_notification,
      }
    });
    setMainId(item.sat_id);
  });

  useEffect(() => {
    const fetch = async () => {
      if (type === 'edit') {
        initDialog();
      }
    };
    fetch();
  }, [type, reset, item]);

  useEffect(() => {
    async function init() {
      await dispatch(getGroupsDropdown());
      await dispatch(getTemplateDropdown());
    }
    init();

    return async () => {

    }
  }, []);

  /**
  * Function to clean the information
  */
  const cleanData = () => {
    setMainId(null);
    reset(defaultValues);
  };

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };
  // --------------------------------------------
  const optionGroups = groups
    ? groups.map((e) => ({
      value: e.seacg_id,
      label: e.seacg_name,
    }))
    : [];

  const optionTemplates = templates
      ? templates.map((e) => ({
        value: e.template_id,
        label: e.template_name,
      }))
      : [];

  const notiOptions = [
    { label: 'No', value: 0 },
    { label: 'Si', value: 1 }
  ];

  // --------------------------------------------

  /**
   * Function to send the new partner information.
   * Checks if the 'PartnersName' field is empty and displays an error if so, otherwise closes the modal.
   * @returns {void} - Returns nothing.
   */
  const handleAction = async (data) => {
    setLoading(true);
    if (type === 'create') {
      const response = await dispatch(create({
        ...data,
        sat_notification: data.sat_notification.value,
        sat_email_templates_id: data.sat_email_templates_id.value,
        sat_group_id: data.sat_group_id.value,
      }));
      if (response) {
        setMainId(response.sat_id)
        await dispatch(showMessage({ message: 'Typo de actividad creada', variant: 'success' }));
        setLoading(false);
        handleRefresh();
      } else {
        setLoading(false);
      }
    } else {
      const response = await dispatch(update({
        ...data,
            sat_notification: data.sat_notification.value,
            sat_email_templates_id: data.sat_email_templates_id.value,
            sat_group_id: data.sat_group_id.value,
      },
        item.sat_id
      ));
      if (response) {
        await dispatch(showMessage({ message: 'Tipo de actividad actualizado', variant: 'success' }));
        cleanData();
        setLoading(false);
        handleClose();
        handleRefresh();
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} TransitionComponent={Transition} className={classes.dialog}>
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          {type === 'create' ? 'Crear' : 'Editar'} Activity
        </p>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <div className="mt-4 flex flex-col">
            <div className="grid md:grid-cols-1 sm:grid-cols-1 grid-cols-1 gap-3">
              <div>
                <Controller
                  name="sat_name"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      multiline
                      rows={3}
                      maxRows={3}
                      styles={{ mt: 1, mb: 2, width: '100%' }}
                      id="sat_name"
                      label="Nombre"
                      // value={form.cli_nombre_empresa}
                      error={!!errors.sat_name}
                      helperText={errors?.sat_name?.message}
                      required
                      //disabled={type === 'create' ? false : true}
                    />
                  )}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
              <div>
                <Controller
                    name="sat_group_id"
                    control={control}
                    render={({field: {onChange, value, onBlur, ref}}) => (
                        <CustomAutocomplete
                            styles={{mb: 2}}
                            label="Grupo"
                            id="sat_group_id"
                            value={value || null}
                            options={optionGroups}
                            onChange={(event, newValue) => {
                              onChange(event);
                            }}
                            renderOption={(props, option) => (
                                <li {...props} key={option.key}>
                                  {option.label}
                                </li>
                            )}
                            error={!!errors.sat_group_id}
                            helperText={errors?.sat_group_id?.message}
                            required
                            //disabled={type === 'create' ? false : true}
                        />
                    )}
                />
              </div>
              <div>
                <Controller
                    name="sat_email_templates_id"
                    control={control}
                    render={({field: {onChange, value, onBlur, ref}}) => (
                        <CustomAutocomplete
                            styles={{mb: 2}}
                            label="Plantilla"
                            id="sat_email_templates_id"
                            value={value || null}
                            options={optionTemplates}
                            onChange={(event, newValue) => {
                              onChange(event);
                            }}
                            renderOption={(props, option) => (
                                <li {...props} key={option.key}>
                                  {option.label}
                                </li>
                            )}
                            error={!!errors.sat_email_templates_id}
                            helperText={errors?.sat_email_templates_id?.message}
                            required
                            //disabled={type === 'create' ? false : true}
                        />
                    )}
                />
              </div>
              <div>
                <Controller
                    name="sat_notification"
                    control={control}
                    render={({field: {onChange, value, onBlur, ref}}) => (
                        <CustomAutocomplete
                            styles={{mb: 2}}
                            label="Estado de notificación"
                            id="sat_notification"
                            value={value || null}
                            options={notiOptions}
                            onChange={(event, newValue) => {
                              onChange(event);
                            }}
                            renderOption={(props, option) => (
                                <li {...props} key={option.key}>
                                  {option.label}
                                </li>
                            )}
                            error={!!errors.sat_notification}
                            helperText={errors?.sat_notification?.message}
                            required
                            //disabled={type === 'create' ? false : true}
                        />
                    )}
                />
              </div>
            </div>
            <div className="flex justify-end items-center">
              <p
                  className="mx-4 underline text-base text-gray-600 cursor-pointer"
                  onClick={() => {
                    handleClose();
                    setLoading(false);
                    cleanData();
                  }}
              >
                Cancel
              </p>
              {loading ? (
                  <CircularProgress
                      style={{color: '#4575D5', marginRight: 4}}
                      size={24}
                  />
              ) : (
                  <CustomButton
                      label="Guardar"
                      typebutton={2}
                      onClick={handleSubmit(handleAction)}
                  />
              )}
            </div>
          </div>
        </FormProvider>
        {/* ---------------------- */}

        {/* ---------------------- */}
      </DialogContent>
    </Dialog>
  );
}

export default CustomDialog;
