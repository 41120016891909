import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton, MenuItem, Select, Box, Tabs, Tab, Typography
} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import FilterRoundedIcon from '@mui/icons-material/FilterRounded';
import AttachEmailRoundedIcon from '@mui/icons-material/AttachEmailRounded';

import CustomLoading from '@components/CustomLoading';
import CustomTableHead from '@components/CustomTableHead';
import CustomTablePagination from '@components/CustomTablePagination';
import CustomButton from '@components/CustomButton';

// import { getComparator, stableSort } from '../../utils/tablesFunctions';
import CustomDialog from './CustomDialog';
import EmailDialog from './EmailDialog';
import CustomFilter from './CustomFilter';

import {
  getActivitiesById,
  setRowsPerPageTwo,
  setPageTwo,
  setCleanSellerActi,
} from '../../../store/sellerActivitySlice';
import UploadFileTab from "../../admin/ServicesList/UploadFileTab";
import ActivitiesTab from "../../admin/ServicesList/ActivitiesTab";
import { getGroupsDropdown } from "../../../store/sellerActivitiesGroups";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Index() {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const authUser = useSelector(({ user }) => user.user);
  const rows = useSelector(({ sellerActi }) => sellerActi.dataById);
  const page = useSelector(({ sellerActi }) => sellerActi.pageTwo);
  const rowsPerPage = useSelector(({ sellerActi }) => sellerActi.rowsPerPageTwo);
  const totalRows = useSelector(({ sellerActi }) => sellerActi.totalTwo);
  const groups = useSelector(({ slaGroup }) => slaGroup.groupDropdown);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(0);
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'seac_date',
  });
  const [addDialog, setAddDialog] = useState(false);
  const [emailDialog, setEmailDialog] = useState(false);
  const [itemSelect, setItemSelect] = useState('');

  const [valueTab, setValueTab] = useState(0);
  const [seacgroups, setSeacgroups] = useState([]);
  const [groupSelected, setGroupSelect] = useState(0);

  useEffect(() => {
    async function init() {
      setLoading(true);
      await dispatch(getGroupsDropdown());
      setLoading(false);
    }
    init();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (rows && rows.length > 0) {
        setItems(rows);
      } else {
        setItems([]);
      }
    };
    fetch();
  }, [rows]);

  useEffect(() => {
    const fetch = async () => {
      if (groups && groups.length > 0) {
        setSeacgroups(groups);
      }
    };
    fetch();
  }, [groups]);

  useEffect(() => {
    return async () => {
      await dispatch(setCleanSellerActi())
    }
  }, []);

  /**
   * Handles the table sort request.
   * @param {string} property - Property to sort the table by.
   */
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };

  /**
   * Handles page change in table pagination.
   * @param {number} newPage - New selected page.
   */
  const handleChangePage = (event, newPage) => {
    dispatch(setPageTwo(newPage));
  };
  /**
   * Handles the change in the number of rows per page in the table pagination.
   * @param {Object} event - React event object.
   */
  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPageTwo(parseInt(event.target.value, 10)));
    dispatch(setPageTwo(0));
  };

  const toggleDialogCreate = () => {
    setAddDialog(!addDialog);
  };

  const toggleDialogEmail = () => {
    setEmailDialog(!emailDialog);
  };

  const handleRefresh = async () => {
    await dispatch(
      getActivitiesById(authUser?.user_id, 0, page, rowsPerPage, order.id, order.direction, {})
    );
  };

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
    setGroupSelect(newValue)
  };

  return (
    <div>
      <div className="w-full flex justify-between items-center mt-4 bg-white p-4 rounded-lg h-16">
        <p className="text-xl font-semibold">{t('my_activities')}</p>
        {/* <CustomButton
          label="Nuevo"
          typebutton={2}
          icon={<AddRoundedIcon fontSize="large" />}
          onClick={toggleDialogCreate}
        /> */}
      </div>
      <div className="mt-7 bg-white px-2 py-6 rounded-lg flex flex-col">
        <div className="bg-red flex justify-end pb-4 px-4">
          <div className="flex">
            <CustomTablePagination
              page={page}
              count={totalRows}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
        {loading ? (
          <CustomLoading />
        ) : (
          <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={valueTab} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Todas las actividades" {...a11yProps(0)} />
                {seacgroups.map((group) => (
                  <Tab key={group.seacg_id} label={group.seacg_name} {...a11yProps(group.seacg_id)} />
                ))}
              </Tabs>
            </Box>
            <Box sx={{ p: 2 }}>
              <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <CustomFilter
                    order={order}
                    onRequestSort={handleRequestSort}
                    groupSelected={groupSelected}
                  />
                  <TableBody>
                    {items && items.length > 0 ? (
                      items.map((e) => {
                        return (
                          <TableRow
                            hover
                            key={e.seac_id}
                          >
                            {/* <TableCell sx={{ p: '15px 3px' }}>{e.seac_id}</TableCell> */}
                            <TableCell sx={{ p: '15px 10px' }}>{e.fk_seller_activity_type?.sat_name}</TableCell>
                            <TableCell
                              sx={{ p: '15px 3px' }}>{format(parseISO(`${e.seac_date}`), "dd-MM-yyyy")}{' '}</TableCell>
                            <TableCell sx={{ p: '15px 3px' }}>{e.seac_description}</TableCell>
                            <TableCell
                              sx={{ p: '15px 3px' }}>{e.seac_buyer_id ? e.fk_buyer?.buyer_company : "--"}</TableCell>
                            <TableCell
                              align="left"
                              sx={{ p: '14px 3px' }}
                            >
                              {e.seller_activities_file.length > 0 && (
                                <IconButton
                                  onClick={() => {
                                    setItemSelect(e);
                                    setAddDialog(true)
                                  }}
                                // href={`https://www.google.com`}
                                // target="_blank"
                                >
                                  <FilterRoundedIcon
                                    style={{ color: '#213462' }}
                                  />
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ p: '14px 3px' }}
                            >
                              {e.seac_copy_email && (
                                <IconButton
                                  onClick={() => {
                                    setItemSelect(e);
                                    setEmailDialog(true)
                                  }}
                                // href={`https://www.google.com`}
                                // target="_blank"
                                >
                                  <AttachEmailRoundedIcon
                                    style={{ color: '#213462' }}
                                  />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ padding: 16, fontSize: 18 }}
                          colSpan={10}
                        >
                          No se han encontrado datos
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
        <div className="flex justify-end mt-5 px-3">
          <CustomTablePagination
            page={page}
            count={totalRows}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {addDialog && (
        <CustomDialog
          item={itemSelect}
          open={addDialog}
          handleClose={toggleDialogCreate}
          handleRefresh={handleRefresh}
          type="create"
        />
      )}
      {emailDialog && (
        <EmailDialog
          item={itemSelect}
          open={emailDialog}
          handleClose={toggleDialogEmail}
          handleRefresh={handleRefresh}
          type="create"
        />
      )}
    </div>
  );
}

export default Index;
