import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';
import { errorResponse } from '../utils/httpResponse'

// *** ROL SELLER ***
export const getActivitySellerById = (userId, id) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_tracking}/user/${userId}?seller_service_id=${id}`;
        const result = await axios.get(URL);
        await dispatch(setDataById(result.data));
        return result;
    } catch (error) {
        return await errorResponse(dispatch, error, 'getActivitySellerById')
    }
};

// *** ROL SELLER ***
export const getServiceDocumentById = (service) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_tracking}/service_document/${service}`;
        const result = await axios.get(URL);
        await dispatch(setServiceDocuments(result.data));
        return result;
    } catch (error) {
        return await errorResponse(dispatch, error, 'getServiceDocumentById')
    }
};

// *** ROL SELLER ***
export const getActivityFile = (activity_id, step) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_tracking}/activity_file?activity_id=${activity_id}&step=${step}`;
        const result = await axios.get(URL);
        await dispatch(setActivityFiles(result.data));
        return true;
    } catch (error) {
        return await errorResponse(dispatch, error, 'getActivityFile')
    }
};

// *** ROL SELLER ***
export const createActivityFile = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_tracking}/activity_file`;
        await axios.post(URL, data, { headers: { 'Content-Type': 'multipart/form-data' } });
        return true;
    } catch (error) {
        return await errorResponse(dispatch, error, 'createActivityFile')
    }
};

// *** ROL SELLER ***
export const deleteActivityFile = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_tracking}/activity_file_delete`;
        await axios.post(URL, data);
        return true;
    } catch (error) {
        return await errorResponse(dispatch, error, 'deleteActivityFile')
    }
};

// *** ROL SELLER ***
export const saveReceptionInfo = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_tracking}/reception_infomation`;
        await axios.post(URL, data);
        return true;
    } catch (error) {
        return await errorResponse(dispatch, error, 'saveReceptionInfo')
    }
};

// *** ROL SELLER ***
export const getObservationById = (id) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_tracking}/observation/${id}`;
        const result = await axios.get(URL);
        await dispatch(setObservation(result.data));
        // return true;
    } catch (error) {
        return await errorResponse(dispatch, error, 'getObservationById')
    }
};

export const saveObservationInfo = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_tracking}/observation`;
        await axios.post(URL, data);
        return true;
    } catch (error) {
        return await errorResponse(dispatch, error, 'saveObservationInfo')
    }
};

export const approveReport = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_tracking}/approve_report`;
        await axios.post(URL, data);
        return true;
    } catch (error) {
        return await errorResponse(dispatch, error, 'approveReport')
    }
};

const sellerTrackingSlice = createSlice({
    name: 'sellerTrack',
    initialState: {
        enabledIntegration: false,
        dataById: [],
        serviceDocuments: [],
        activityFiles: [],
        observation: [],
    },
    reducers: {
        setCleanSellerTrack: (state) => {
            state.dataById = [];
            state.serviceDocuments = [];
        },
        setDataById: (state, action) => {
            state.dataById = action.payload;
        },
        setServiceDocuments: (state, action) => {
            state.serviceDocuments = action.payload;
        },
        setActivityFiles: (state, action) => {
            state.activityFiles = action.payload;
        },
        setObservation: (state, action) => {
            state.observation = action.payload;
        },
    }
});

export const { setCleanSellerTrack, setDataById, setServiceDocuments, setActivityFiles, setObservation } =
    sellerTrackingSlice.actions;

export default sellerTrackingSlice.reducer;
