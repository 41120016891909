import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';

export const getGroupsDropdown = () => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.selleractivitiesgroups}/dropdown`;
        const result = await axios.get(URL);
        await dispatch(setGroupsDropdown(result.data));
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> getGroupsDropdown *** ${error}`);
    }
};

export const getAll = (page, per_page, orderBy, order, params) => async (dispatch) => {
    try {
        const queryString = `?page=${page}&rows=${per_page}&order_field=${orderBy}&order_type=${order}&filters=${JSON.stringify(params)}`;
        const URL = `${API.baseUrl}/${API.endpoints.selleractivitiesgroups}`;
        const result = await axios.get(URL + queryString);
        await dispatch(setGroupsList(result.data));
        return result;
    } catch (error) {
        throw new Error(`*** REDUX -> getAll *** ${error}`);
    }
};

export const create = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.selleractivitiesgroups}`;
        await axios.post(URL, data);
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> create *** ${error}`);
    }
};

export const update = (data, id) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.selleractivitiesgroups}/${id}`;
        await axios.put(URL, data);
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> update *** ${error}`);
    }
};

export const deleteGroup = (id) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.selleractivitiesgroups}/${id}`;
        await axios.delete(URL, id);
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> delete *** ${error}`);
    }
};

const initialState = {
    dataList: [],
    page: 0,
    rowsPerPage: 10,
    total: 0,
    groupDropdown: [],
};

const sellerActivitiesGroupsSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        setGroupsList: (state, action) => {
            state.dataList = action.payload.rows;
            state.total = action.payload.count;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setGroupsDropdown: (state, action) => {
            state.groupDropdown = action.payload;
        },
    },
});

export const { setGroupsList, setRowsPerPage, setPage, setGroupsDropdown } = sellerActivitiesGroupsSlice.actions;

export default sellerActivitiesGroupsSlice.reducer;