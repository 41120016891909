import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { AppBar, IconButton, MenuItem, Popover, Toolbar } from '@mui/material';
import LanguageSwitcher from './LanguageSwitcher';

import { useAuth } from '../../auth/services/AuthContext';
import { setCleanUser } from '../../store/userSlice';
import { setCleanI18n } from '../../store/i18nSlice';


function Header(props) {
    const { toggleDrawer } = props;

    const { /*setToken, setAuthUser,*/ signOut } = useAuth();
    const authUser = useSelector(({ user }) => user.user);
    console.log('authUser', authUser)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userMenu, setUserMenu] = useState(null);

    const userMenuClick = event => {
        setUserMenu(event.currentTarget);
    };

    const userMenuClose = () => {
        setUserMenu(null);
    };

    return (
        <AppBar
            position="fixed"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: 'white', boxShadow: 'none', borderBottom: '1px solid #EEEEEE' }}
        >
            <Toolbar className='flex justify-between'>
                <div className="flex items-center">
                    <IconButton
                        edge="start"
                        onClick={toggleDrawer}
                        sx={{ mr: 3, background: '#09283859', borderRadius: 2, p: '5px' }}
                    >
                        <MenuIcon fontSize="small" sx={{ color: '#092838' }} />
                    </IconButton>
                    <RouterLink to="/">
                        <img src='/bosanet-new-black.png' width='140' height='58' alt='bosanet.png' />
                    </RouterLink>
                </div>
                <div className="flex items-center">
                    <LanguageSwitcher />
                    {authUser?.user_rol_id && authUser?.user_rol_id === 2 && (
                        <img src='/assets/images/Seller.png' className="min-w-16 max-w-16" alt='Seller.png' />
                    )}
                    {authUser?.user_rol_id && authUser?.user_rol_id === 3 && (
                        <img src='/assets/images/Supplier.png' className="min-w-16 max-w-16" alt='Supplier.png' />
                    )}
                    {authUser?.user_rol_id && authUser?.user_rol_id === 4 && (
                        <img src='/assets/images/Bosanet.png' className="min-w-16 max-w-16" alt='Bosanet.png' />
                    )}
                    {authUser?.user_rol_id && authUser?.user_rol_id === 5 && (
                        <img src='/assets/images/Partner.png' className="min-w-16 max-w-16" alt='Partner.png' />
                    )}
                    <div className="flex flex-col ml-2">
                        <p style={{ color: 'black', fontSize: 16, fontWeight: 600 }}>
                            {authUser?.user_username}
                        </p>
                        <span className="text-xs text-black">{authUser?.fk_role?.role_description}</span>
                    </div>
                    <IconButton
                        onClick={userMenuClick}
                        sx={{ ml: 1, background: '#09283859', borderRadius: 2, p: '3px' }}
                    >
                        <ExpandMoreRoundedIcon
                            fontSize="medium"
                            style={{ color: '#092838', cursor: 'pointer' }}
                        />
                    </IconButton>
                    <Popover
                        open={Boolean(userMenu)}
                        anchorEl={userMenu}
                        onClose={userMenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem
                            style={{ color: '#979797' }}
                            onClick={async () => {
                                await dispatch(setCleanUser())
                                await dispatch(setCleanI18n())
                                setUserMenu(null);
                                signOut();
                                navigate("/sign-in", { replace: true });
                            }}
                        >
                            Sign out
                        </MenuItem>
                    </Popover>
                </div>
            </Toolbar>
        </AppBar>
    );
}

export default Header;