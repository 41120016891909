import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';

export const getTypesDropdown = () => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_activities_types}/dropdown`;
        const result = await axios.get(URL);
        await dispatch(setTypesDropdown(result.data));
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> getTypesDropdown *** ${error}`);
    }
};

export const getAll = (page, per_page, orderBy, order, params) => async (dispatch) => {
    try {
        const queryString = `?page=${page}&rows=${per_page}&order_field=${orderBy}&order_type=${order}&filters=${JSON.stringify(params)}`;
        const URL = `${API.baseUrl}/${API.endpoints.seller_activities_types}`;
        const result = await axios.get(URL + queryString,{
            params: {
                include: 'sellerActivitiesGroups' ,
            }
        });
        await dispatch(setTypesList(result.data));
        return result;
    } catch (error) {
        throw new Error(`*** REDUX -> getAll *** ${error}`);
    }
};

export const create = (data) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_activities_types}`;
        await axios.post(URL, data);
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> create *** ${error}`);
    }
};

export const update = (data, id) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_activities_types}/${id}`;
        await axios.put(URL, data);
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> update *** ${error}`);
    }
};

export const deleteType = (id) => async (dispatch) => {
    try {
        const URL = `${API.baseUrl}/${API.endpoints.seller_activities_types}/${id}`;
        await axios.delete(URL, id);
        return true;
    } catch (error) {
        throw new Error(`*** REDUX -> delete *** ${error}`);
    }
};

const initialState = {
    dataList: [],
    page: 0,
    rowsPerPage: 10,
    total: 0,
    typeDropdown: [],
};

const sellerActivitiesTypesSlice = createSlice({
    name: 'type',
    initialState,
    reducers: {
        setTypesList: (state, action) => {
            state.dataList = action.payload.rows;
            state.total = action.payload.count;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setTypesDropdown: (state, action) => {
            state.typeDropdown = action.payload;
        },
    },
});

export const { setTypesList, setRowsPerPage, setPage, setTypesDropdown } = sellerActivitiesTypesSlice.actions;

export default sellerActivitiesTypesSlice.reducer;